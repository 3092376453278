import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface ModalPortalProps {
    children: ReactNode // ReactNode allows for more flexibility than ReactElement
}

const ModalPortal = ({ children }: ModalPortalProps) => {
    const [mounted, setMounted] = useState<boolean>(false)

    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    return mounted ? createPortal(children, document.body) : null
}

export default ModalPortal
