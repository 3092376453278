import { useEffect } from 'react'

// Specify 'date' as a Date object or null and 'func' as a function that returns void
export function useDefer(date: Date | null, func: () => void): void {
    useEffect(() => {
        if (!date) {
            return
        }
        const delta = date.valueOf() - Date.now()
        if (delta <= 0) return undefined
        const timeout = setTimeout(func, delta)
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])
}
