import React from 'react'

import InputComponent from '../InputComponent/InputComponent.tsx'

import { useTheme } from 'next-themes'

import DayTimeIcon from '@/icons/DayTimeIcon.tsx'
import NightTimeIcon from '@/icons/NightTimeIcon.tsx'

const ThemeChanger = () => {
    const { setTheme, theme } = useTheme()

    return (
        <label className="swap swap-rotate">
            <InputComponent
                type="checkbox"
                className="input-ghost"
                onClick={() => {
                    if (theme === 'dark') {
                        setTheme('light')
                    } else {
                        setTheme('dark')
                    }
                }}
            />

            <NightTimeIcon className="w-10 h-10 fill-current swap-on !transition-all duration-75" />

            <DayTimeIcon className="w-10 h-10 fill-current swap-off !transition-all duration-75" />
        </label>
    )
}

export default ThemeChanger
