import { useMemo, useSyncExternalStore } from 'react'

/* The purpose of this hook is to listen for document visibilitychange using the useSyncExternalStore thus we can expose the */
export function useVisibilityChange(serverFallback = false) {
    // getServerSnapshot is used for Server Side Rendering, where document is not available
    const getServerSnapshot = () => serverFallback

    const [getSnapshot, subscribe] = useMemo(() => {
        return [
            // This function returns the current state
            () => document.visibilityState === 'visible',

            // This function sets up a subscription to the external store
            (notify: () => void) => {
                const handleChange = () => notify()
                window.addEventListener('visibilitychange', handleChange)

                // Return a cleanup function that removes the event listener
                return () => window.removeEventListener('visibilitychange', handleChange)
            },
        ]
        // Empty dependency array to run it only once
    }, [])

    /*   useSyncExternalStore will use the subscription and snapshot functions
     to keep this component in sync with the visibility state */
    const isPageVisible = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)

    return isPageVisible
}
