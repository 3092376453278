import React, { InputHTMLAttributes, forwardRef } from 'react'

// Extend from InputHTMLAttributes to include all standard HTML input attributes
interface InputComponentProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | JSX.Element
    className?: string
    labelClass?: string
}

const InputComponent: React.ForwardRefRenderFunction<HTMLInputElement, InputComponentProps> = (
    {
        label,
        className = 'text-lg font-semibold text-center input input-bordered',
        labelClass = 'label justify-start font-semibold',
        ...props // This now includes all input attributes such as name, type, required, value, onChange, etc.
    },
    ref
) => {
    return (
        <>
            {label && (
                <label htmlFor={props.name} className={labelClass}>
                    {label}
                    {props.required && <span className="text-red-500">*</span>}
                </label>
            )}
            <input
                {...props}
                className={className}
                ref={ref} // Attach the forwarded ref here
            />
        </>
    )
}

InputComponent.displayName = 'InputComponent'

export default forwardRef(InputComponent)
