import React from 'react'

import Head from 'next/head'

type SEOHeadComponentT = {
    title: string
    description: string
    canonicalUrl: string
    ogType: string
    children: React.ReactNode
    siteName: string
    siteLogo?: string
}

const SEOHeadComponent = ({
    title,
    description,
    canonicalUrl,
    ogType,
    children,
    siteName,
    siteLogo,
}: SEOHeadComponentT) => {
    return (
        <Head key={title}>
            {/* Basic metadata */}
            <title>{title}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />

            {/* Canonical link */}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

            {/* Open Graph metadata */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:type" content={ogType} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={siteLogo} />
            <meta property="og:url" content={canonicalUrl} />

            {/* Favicon */}
            <link rel="icon" type="image/x-icon" href={siteLogo} />
            {children}
        </Head>
    )
}

export default SEOHeadComponent
