import { DependencyList, useEffect } from 'react'

import { useVisibilityChange } from './useVisibilityChange'

import { debounce } from '@/utils/functionUtils'

const useFetchOnFocus = (
    callback: () => void | Promise<void>,
    dependencies: DependencyList,
    debounceTimer = 100
): void => {
    //  custom hook to get the current visibility state
    const isPageVisible = useVisibilityChange()

    useEffect(() => {
        // Define a debounced version of the callback
        const debouncedCallback = debounce(callback, debounceTimer)

        // Call the callback immediately if the page is visible
        if (isPageVisible) {
            debouncedCallback()
        }

        // Listen for focus events as a fallback
        window.addEventListener('focus', debouncedCallback, false)

        return () => {
            // Cleanup event listeners

            window.removeEventListener('focus', debouncedCallback)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageVisible, ...dependencies])
}

export default useFetchOnFocus
