import React from 'react'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

import { BasicButtonProps } from '@/types/globalTypes'

const BasicButton = ({
    type = 'button',
    label,
    onClick,
    disabled,
    className,
    icon,
    labelClassName,
    children,
    isLoading = false,
}: BasicButtonProps) => {
    return (
        <button
            type={type}
            className={className}
            onClick={onClick}
            disabled={disabled || isLoading}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {icon}
                    {label ? <span className={labelClassName}>{label}</span> : null}
                    {children}
                </>
            )}
        </button>
    )
}

export default BasicButton
