import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast'

import { useApplicationContext } from '@/context/ApplicationContext.tsx'

import { NOTIFICATION_TYPE } from '@/constants/constants.ts'

import { ToastNotificationComponentProps } from '@/types/globalTypes'

const ToastNotificationComponent = ({
    buttons,
    icon,
    options,
}: ToastNotificationComponentProps) => {
    const { setToastNotification, toastNotification } = useApplicationContext()

    const { type, message, promise } = toastNotification

    useEffect(() => {
        if (type === NOTIFICATION_TYPE.PROMISE) {
            toast.promise(promise, {
                loading: 'Loading...',
                success: message,
                error: message,
            })

            setToastNotification({
                type: '',
                message: '',
                promise: null,
            })
        }

        if (type === NOTIFICATION_TYPE.INFO) {
            toast.custom(
                (t) => (
                    <div
                        className={`${
                            t.visible ? 'animate-enter' : 'animate-leave'
                        } alert alert-info  max-w-sm w-full  shadow-lg rounded-lg pointer-events-auto flex flex-row`}
                    >
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                className="flex-shrink-0 w-8 h-8 stroke-current"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                            </svg>
                            <span>{message}</span>
                        </div>
                        <div className="flex-none !my-auto ">
                            <button
                                className="btn btn-circle btn-xs"
                                onClick={() => toast.dismiss(t.id)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4 h-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                ),
                options
            )
        }

        if (type === NOTIFICATION_TYPE.ERROR) {
            toast.error(message)
            setToastNotification({
                type: '',
                message: '',
                promise: null,
            })

            /*  toast.custom((t) => (
                 <div
                     className={`${t.visible ? 'animate-enter' : 'animate-leave'
                         }  alert alert-error max-w-sm w-full  shadow-lg rounded-lg pointer-events-auto flex flex-row`}
                 >
                     <div>
                         <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-8 h-8 stroke-current" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                         <span>{message}</span>
                     </div>
                     <div className="flex-none !my-auto ">
                         <button className="btn btn-circle btn-xs" onClick={() => toast.dismiss(t.id)}>
                             <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /> </svg>
                         </button>
                     </div>
                 </div>

             ), options) */
        }

        if (type === NOTIFICATION_TYPE.SUCCESS) {
            toast.success(message)
            setToastNotification({
                type: '',
                message: '',
                promise: null,
            })

            /*  toast.custom((t) => (
                 <div
                     className={`${t.visible ? 'animate-enter' : 'animate-leave'
                         } alert alert-success max-w-sm w-full  shadow-lg rounded-lg pointer-events-auto flex flex-row `}
                 >
                     <div>
                         <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-8 h-8 stroke-current" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                         <span>{message}</span>
                     </div>
                     <div className="flex-none !my-auto ">
                         <button className="btn btn-circle btn-xs" onClick={() => toast.dismiss(t.id)}>
                             <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                         </button>
                     </div>
                 </div>

             ), options)
      */
        }
        if (type === NOTIFICATION_TYPE.WARNING) {
            toast.custom(
                (t) => (
                    <div
                        className={`${
                            t.visible ? 'animate-enter' : 'animate-leave'
                        } alert alert-success max-w-sm w-full  shadow-lg rounded-lg pointer-events-auto flex flex-row`}
                    >
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-shrink-0 w-8 h-8 stroke-current"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                            </svg>
                            <span>{message}</span>
                        </div>
                        <div className="flex-none !my-auto ">
                            <button
                                className="btn btn-circle btn-xs"
                                onClick={() => toast.dismiss(t.id)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4 h-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                ),
                options
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, promise, toastNotification])

    return null
}

export default ToastNotificationComponent
